<template>
  <div class="pdfdetail">
    <div class="pdf">
      <pdf ref="pdf" v-for="i in numPages" :key="i" :src="pdfUrl" :page="i">
      </pdf>
    </div>
  </div>
</template>
<script>
import pdf from "vue-pdf";
import CMapReaderFactory from "vue-pdf/src/CMapReaderFactory.js";
import {
  isDev,
  requestDataTGPMethod,
  generateUUID,
  requestQueryDataTGPMethod,
} from "@/utils/appManager";
export default {
  name: "pdfdetail",
  components: { pdf },
  data() {
    return {
      source: "",
      pdfUrl: "",
      numPages: 1,
      idStr: "",
      articleDetail: {},
      stockCode: "",
      id: "",
    };
  },
  methods: {
    pdfTask(pdfUrl) {
      var self = this;
      this.pdfUrl = pdf.createLoadingTask({ url: pdfUrl, CMapReaderFactory });
      console.log("pdf", this.pdfUrl);
      this.pdfUrl.promise
        .then((pdf) => {
          self.numPages = pdf.numPages;
        })
        .catch((err) => {
          console.error("pdf加载失败");
        });
    },
    createResourseAction() {
      if (this.articleDetail.resources != undefined) {
        for (var i = 0; i < this.articleDetail.resources.length; i++) {
          var oneResource = this.articleDetail.resources[i];
          console.log(oneResource);
          if (oneResource.type == 1) {
          } else if (oneResource.type == 0) {
          } else if (oneResource.type == 2) {
            this.source = oneResource.url;
            this.pdfTask(this.source);
            break;
          }
        }
      }
    },
    getstockReport() {
      requestDataTGPMethod(
        "requestData",
        "v5/stock/stockReport",
        {
          stockCode: this.stockCode,
          page: 1,
          pageSize: 1,
          orderBy: 1,
          id: this.id,
        },
        "getstockReport",
        "0"
      );
      var viewport = document.querySelector("meta[name=viewport]");
      viewport.setAttribute(
        "content",
        "width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=4.0, user-scalable=yes"
      );
    },
  },
  mounted() {
    const { id } = this.$route.params;
    console.log(id);
    var array = id.split("ransang");

    this.stockCode = array[0];
    this.id = array[1];
    this.getstockReport();
  },
  created() {
    window["getstockReport"] = (data) => {
      console.log(
        "=================getstockReport======================",
        data
      );
      window.document.title = "研报";
      this.source = data.data[0].pdfUrl;
      this.pdfTask(this.source);

      this.articleDetail = data.data;
      setTimeout(() => {
        this.createResourseAction();
      }, 300);
    };
 
  },
};
</script>
<style scoped lang="scss">
.pdfdetail {}
</style>
